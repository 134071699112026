@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    
}
body{
    /* background-color: #01061f; */
    background: url('./assets/bg.jpg');
    background-repeat: repeat-y;
    background-attachment: fixed;
    background-size: cover;
    height: 100vh;
}
.ButtonBg{
    /* background: linear-gradient(135deg, 
    #6A35EE 0%, 
    #9930EE 15%, 
    #5737EE 53%, 
    #795CEE 100%); */
    background-color: black;
}
.headerText{
    font-size: 20px;
}
.bgImage{
    background: url('./assets/headerBg.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: fixed;
}
.headerBg{
    background: rgba(255, 255, 255, 0.5);
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(2px);
-webkit-backdrop-filter: blur(5px);
border-top: 1px solid rgba(255, 255, 255, 0.3);
border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.purpleBg{
    background-color: #8c52ff;
}
.borderStyle{
    border: 0.01px solid rgb(92, 92, 92);
}
.borderBottom{
    border-bottom: 1px solid rgb(208, 207, 207);
}
.backgroundImage{
    background: url('./assets//bg1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}
.gradientText{
        
    background: #121FCF;
    background: linear-gradient(to right, #121FCF 0%, #CF1512 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      line-height: normal;
}

/* customSliderStyles.css */

/* Ensuring the slider container fills the parent dimensions */
.custom-slider-container {
    width: 100%;
    height: 100%;
  }
  
  /* Ensuring images take the full height and maintain aspect ratio */
  .custom-slider-image {
    width: auto;
    height: 75%;
    object-fit: cover;
  }
  
  /* Customizing the navigation buttons */
  .aws-btn {
    color: white; /* Change this to the color you want for the buttons */
    background-color: black; /* Optionally change the background color */
  }
  
  @media (max-width: 1024px) {
    .custom-slider-container {
      height: 350px;
    }
  }
  
  @media (max-width: 768px) {
    .custom-slider-container {
      height: 250px;
    }
  }
  
  @media (max-width: 640px) {
    .custom-slider-container {
      height: 200px;
    }
  }
  .bgService {
    position: relative;
    background: url('./assets/bgForServices.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .bgService::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Adjust the RGB values for brightness and the alpha value for opacity */
    z-index: 1; /* Ensure the overlay is on top of the background */
  }
  
  .bgService > * {
    position: relative;
    z-index: 2; /* Ensure the content is above the overlay */
  }

  .bgAbout {
    position: relative;
    background: url('./assets/bgForAbout.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .bgAbout::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Adjust the RGB values for brightness and the alpha value for opacity */
    z-index: 1; /* Ensure the overlay is on top of the background */
  }
  
  .bgAbout > * {
    position: relative;
    z-index: 2; /* Ensure the content is above the overlay */
  }
  
  
  
  .my-masonry-grid_column {
    background-clip: padding-box;
  }
  